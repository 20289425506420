import { Pane } from "tweakpane";
import { easings } from "../../easing";

export function buildGUI(state, hudapp, folder) {
  let pane = folder ? folder : new Pane();
  const input = document.createElement("input");
  const easingsNames = Object.keys(easings).reduce((a, c) => {
    const n = { ...a };
    n[c] = c;
    return n;
  }, {});

  const keyframes = pane.addFolder({ title: "Keyframes", expanded: false });

  state.keyframes.forEach((keyframe, i) => {
    const f = keyframes.addFolder({ title: "Keyframe" + i, expanded: false });
    f.addBinding(keyframe, "t", {
      min: 0,
      max: 1,
    });

    f.addBinding(keyframe, "stretchFactor", {
      min: 0,
      max: 3,
    });
    f.addBinding(keyframe, "fov", {
      min: 0,
      max: 150,
    });
    f.addBinding(keyframe, "cameraPosition", {
      x: { min: -1, max: 1 },
      y: { min: -1, max: 1 },
      z: { min: -1, max: 1 },
    });
    f.addBinding(keyframe, "texturePosition", {
      x: { min: -2, max: 2 },
      y: { min: -2, max: 2 },
      z: { min: -2, max: 2 },
    });

    f.addBinding(keyframe, "textureScale", {
      x: { min: 0, max: 5 },
      y: { min: 0, max: 5 },
    });
  });

  const scene = pane.addFolder({ title: "Scene", expanded: false });
  const carsg = scene.addFolder({ title: "Cars", expanded: false });

  state.scene.cars.forEach((card, index) => {
    const folder = carsg.addFolder({ title: "Car" + index, expanded: false });
    card.keyframes.forEach((keyframe, j) => {
      const keyframeFolder = folder.addFolder({
        title: "Keyframe" + j,
        expanded: false,
      });
      keyframeFolder.addBinding(keyframe, "t", { min: 0, max: 1 });
      keyframeFolder.addBinding(keyframe, "position", {
        x: { min: -5, max: 5 },
        y: { min: -5, max: 5 },
        z: { min: -5, max: 5 },
      });
      keyframeFolder.addBinding(keyframe, "rotation", {
        x: { min: -Math.PI, max: Math.PI },
        y: { min: -Math.PI, max: Math.PI },
        z: { min: -Math.PI, max: Math.PI },
      });
      keyframeFolder.addBinding(keyframe, "scale", {
        x: { min: 0, max: 2 },
        y: { min: 0, max: 2 },
        z: { min: 0, max: 2 },
      });
    });
  });

  const camerag = scene.addFolder({ title: "Camera", expanded: false });
  camerag.addBinding(state.scene.camera, "s", { min: 0.0001, max: 1 });
  camerag.addBinding(state.scene.camera, "fov", { min: 0.0, max: 150 });
  camerag.addBinding(state.scene.camera, "position", {
    x: { min: -1, max: 1 },
    y: { min: -1, max: 1 },
    z: { min: -1, max: 0 },
  });
  const texg = scene.addFolder({ title: "Texture", expanded: false });
  texg.addBinding(state.scene.texture, "position", {
    x: { min: -10, max: 10 },
    y: { min: -10, max: 10 },
    z: { min: -10, max: 10 },
  });

  texg.addBinding(state.scene.texture, "scale", {
    x: { min: 0, max: 5 },
    y: { min: 0, max: 5 },
  });
  texg.addBinding(state.scene.texture, "rotation", {
    min: -Math.PI,
    max: Math.PI,
  });
  texg.addBinding(state.scene.texture, "visible");

  const planeg = scene.addFolder({ title: "Planes", expanded: false });
  state.scene.planes.forEach((plane, index) => {
    const planeFolder = planeg.addFolder({
      title: "Plane" + index,
      expanded: false,
    });
    planeFolder.addBinding(plane, "position", {
      x: { min: -10, max: 10 },
      y: { min: -10, max: 10 },
      z: { min: -10, max: 10 },
    });

    planeFolder.addBinding(plane, "rotation", {
      x: { min: -Math.PI, max: Math.PI },
      y: { min: -Math.PI, max: Math.PI },
      z: { min: -Math.PI, max: Math.PI },
    });

    planeFolder.addBinding(plane, "scale", {
      x: { min: 0, max: 5 },
      y: { min: 0, max: 5 },
    });
    planeFolder.addBinding(plane, "visible");
  });

  state.scene.boxes.forEach((box, index) => {
    const boxg = scene.addFolder({ title: "Box" + index, expanded: false });
    boxg.addBinding(box, "position", {
      x: { min: -10, max: 10 },
      y: { min: -10, max: 10 },
      z: { min: -10, max: 10 },
    });

    boxg.addBinding(box, "scale", {
      x: { min: 0, max: 5 },
      y: { min: 0, max: 5 },
      z: { min: 0, max: 5 },
    });
    boxg.addBinding(box, "rotation", { min: -Math.PI, max: Math.PI });
    boxg.addBinding(box, "visible");
  });

  const inner = pane.addFolder({ title: "Inner Transition", expanded: false });

  inner.addBinding(state, "stretchMultiplier", {
    x: { min: 0, max: 1 },
    y: { min: 0, max: 1 },
  });
  inner.addBinding(state, "innerTimeMultiplier", { min: 0.0, max: 0.2 });
  inner.addBinding(state, "transitionVelocity", { min: 0.0, max: 0.2 });

  inner.addBinding(state, "transitionEasing", {
    options: easingsNames,
  });
  inner.addBinding(state, "stretchAmplitude", { min: 0, max: 1 });

  const options = pane.addFolder({ title: "Options", expanded: false });

  options
    .addBinding(state, "parallaxType", {
      options: {
        steep: "steep",
        occlusion_mapping: "occlusion_mapping",
        original: "original",
      },
    })
    .on("change", () => {
      hudapp.setParallax();
    });

  options
    .addBinding(state, "backgroundRotationVelocity", { min: 0, max: 10 })
    .on("change", () => {});
  options
    .addBinding(state, "backgroundRotationScale", { min: 0, max: 10 })
    .on("change", () => {});

  options
    .addBinding(state, "fovModifierWide", { min: 0, max: 1 })
    .on("change", () => {
      hudapp.setHfactor();
    });
  options
    .addBinding(state, "fovModifierNarrow", { min: 0, max: 10 })
    .on("change", () => {
      hudapp.setHfactor();
    });

  options.addBinding(state, "cropy", { min: 0, max: 1 }).on("change", () => {});
  options.addBinding(state, "scale", { min: 0, max: 5 }).on("change", () => {
    hudapp.setPlaneScale();
  });
  options.addBinding(state, "usetaa");
  options
    .addBinding(state, "taaFactor", { min: 0, max: 1 })
    .on("change", () => {});
  // options
  //   .addBinding(state, "nearestDepth", { min: 0, max: 2 })
  //   .on("change", () => {});

  options
    .addBinding(state, "numLayers", {
      options: {
        8: 8,
        16: 16,
        32: 32,
        64: 64,
        128: 128,
        256: 256,
      },
    })
    .on("change", () => {
      hudapp.setNumLayers();
    });

  options.addBinding(state, "useGyro");
  options.addBinding(state, "gyroVelocity", { min: 0.001, max: 0.8 });
  options.addBinding(state, "reverseX");
  options.addBinding(state, "reverseY");

  options.addBinding(state, "useHoverPause");
  options.addBinding(state, "useHoverTimeout");
  options.addBinding(state, "hoverTimeout", {
    min: 500,
    max: 10000,
    step: 500,
  });

  options.addBinding(state, "depthThreshold", { min: 0, max: 1 });
  options.addBinding(state, "depthGrain", { min: 0, max: 1 });
  options.addBinding(state, "grain").on("change", () => {
    hudapp.setGrain();
  });
  options.addBinding(state, "bonusMode").on("change", () => {
    hudapp.setBonusMode();
  });
  options.addBinding(state, "useKeyFrames");

  options.addButton({ title: "Play" }).on("click", () => {
    hudapp.playVideo();
  });
  options.addButton({ title: "Pause" }).on("click", () => {
    hudapp.pauseVideo();
  });

  pane
    .addBinding(state, "pixelRatio", { min: 0.5, max: 3 })
    .on("change", () => {
      hudapp.setPixelRatio();
    });
  pane.addBinding(state, "fovKeyFramed");
  pane.addBinding(state, "runningTime", { readonly: true });
  pane.addBinding(state, "finishTime", { min: 0, max: 1 });
  pane.addBinding(state, "time", { min: 0, max: 1 }).on("change", () => {
    hudapp.setVideoTime(state.time);
  });

  options.addButton({ title: "Save State" }).on("click", () => {
    const a = document.createElement("a");

    // updateStateCurves();
    const stateToExport = { ...hudapp.state };
    let file = new Blob([JSON.stringify(stateToExport)], {
      type: "text/plain",
    });
    a.href = URL.createObjectURL(file);
    a.download = "hud_state.json";
    a.click();
  });

  options
    .addButton({
      title: "Load State",
    })
    .on("click", () => {
      input.type = "file";
      input.click();
    });

  options
    .addButton({
      title: "Import",
    })
    .on("click", () => {
      if (input.files && input.files.length > 0) {
        input.files[0].text().then((res) => {
          let newState = JSON.parse(res);
          hudapp.readState(newState);
          pane.refresh();
        });
      }
    });
}

import * as THREE from "three";
import device from "current-device";
import texMaterial from "../../materials/tex2";
import taaMaterial from "../../materials/taa";
import transitionMaterial from "../../materials/transition";
import { easings } from "../../easing";

let tmouseX = 0;
let tmouseY = 0;

const parallaxDict = {
  steep: 3,
  occlusion_mapping: 1,
  original: 2,
};

const power = 1.0;
const amplitude = 0.95;
let hfactor = 1;

function getStretch(time, state) {
  return state.stretchAmplitude * Math.pow(Math.sin(Math.PI * time), power);
}
function getTransition(time, state) {
  return easings[state.transitionEasing](time);
}

class TaaModule {
  constructor(vdtex) {
    this.scene = new THREE.Scene();
    this.plane = new THREE.Mesh(new THREE.PlaneGeometry(1, 1), taaMaterial());
    this.plane.material.uniforms.dtex.value = vdtex;
    this.plane.frustumCulled = false;
    this.scene.add(this.plane);

    this.rt = new THREE.WebGLRenderTarget(1920, 1080 * 2);
    this.fakeCamera = new THREE.PerspectiveCamera();
  }

  render(dt, state, renderer) {
    this.plane.material.uniforms.taaFactor.value = state.taaFactor * (dt / 8);
    this.plane.material.uniforms.time.value = performance.now();
    this.plane.material.uniforms.depthGrain.value = state.depthGrain;

    const prevAutoClear = renderer.autoClear;
    renderer.autoClear = false;
    renderer.setRenderTarget(this.rt);
    renderer.render(this.scene, this.fakeCamera);
    renderer.setRenderTarget(null);
    renderer.autoClear = prevAutoClear;
  }

  getTexture() {
    return this.rt.texture;
  }
}

let prev = Date.now();

export default class VideoApp {
  constructor({ state, composed, renderer, container, stats }) {
    this.state = state;
    this.composed = composed;
    this.type = "video";

    if (stats) {
      this.stats = stats;
      document.body.append(stats.dom);
    }

    if (!composed) {
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setPixelRatio(state.pixelRatio);
      this.renderer.autoClear = false;

      this.container = document.querySelector(".container");
      this.container.append(this.renderer.domElement);

      window.addEventListener("mousemove", this.onDocumentMouseMove, false);
      window.addEventListener("touchmove", this.onDocumentTouchMove, false);
    } else {
      this.renderer = renderer;
      this.container = container;

      this.renderTarget = new THREE.WebGLRenderTarget(
        state.pixelRatio * window.innerWidth,
        state.pixelRatio * window.innerHeight,
      );

      // if (!device.mobile() && !device.tablet() && ANTIALIAS) {
      //   this.renderTarget.samples = 4;
      // }
    }

    this.dvideo = document.createElement("video");
    this.dvideo2 = document.createElement("video");
    this.dvideos = [this.dvideo, this.dvideo2];

    this.setUpAndLoadVideo(
      this.state.startVideo ? this.state.startVideo : NAME,
    );

    this.rotation = 0;
    this.mouseX = 0;
    this.mouseY = 0;

    this.transitionRenderTarget1 = new THREE.WebGLRenderTarget(
      state.pixelRatio * window.innerWidth,
      state.pixelRatio * window.innerHeight,
    );

    this.transitionRenderTarget2 = new THREE.WebGLRenderTarget(
      state.pixelRatio * window.innerWidth,
      state.pixelRatio * window.innerHeight,
    );

    this.transitionRenderTargets = [
      this.transitionRenderTarget1,
      this.transitionRenderTarget2,
    ];

    this.time = 0;

    this.endTransitionStructure = {
      transition: 0,
      stretch: 0,
      time: 1,
    };

    this.endTargetTransitionStructure = {
      transition: 0,
      stretch: 0,
      time: 1,
    };

    this.from = 1;
    this.to = 0;

    this.transitionRunning = false;
    this.deviceMotion = 0;

    window.addEventListener("devicemotion", (event) => {
      if (!this.dt) return;
      const velocity =
        window.innerHeight > window.innerWidth
          ? event.rotationRate.beta
          : event.rotationRate.alpha;

      const target = velocity > 0.0 ? 1.0 : -1.0;
      this.deviceMotion +=
        (target - this.deviceMotion) *
        (Math.abs(velocity) / 180) *
        this.state.gyroVelocity *
        (this.dt / 16);
      // this.deviceMotion = Math.min(1.0, Math.max(-1.0, this.deviceMotion));
    });

    window.addEventListener("resize", () => {
      hfactor = window.innerWidth / window.innerHeight / 2.1;
      hfactor =
        1.0 -
        (hfactor > 1.0
          ? this.state.fovModifierWide * hfactor
          : hfactor < 0.5
          ? -this.state.fovModifierNarrow * hfactor
          : 0.0);

      const hHeight = 0.5;
      const hWidth = (window.innerWidth * hHeight) / window.innerHeight;
      this.camera.left = -hWidth * hfactor;
      this.camera.right = hWidth * hfactor;
      this.camera.top = hHeight * hfactor;
      this.camera.bottom = -hHeight * hfactor;

      this.camera.updateProjectionMatrix();

      this.renderer.setSize(window.innerWidth, window.innerHeight);
    });
  }

  setHfactor() {
    const hHeight = 0.5;
    const hWidth = (window.innerWidth * hHeight) / window.innerHeight;

    hfactor = window.innerWidth / window.innerHeight / 2.1;
    hfactor =
      1.0 -
      (hfactor > 1.0
        ? this.state.fovModifierWide * hfactor
        : hfactor < 0.5
        ? -this.state.fovModifierNarrow * hfactor
        : 0.0);

    const camera = this.camera;
    camera.left = -hWidth * hfactor;
    camera.right = hWidth * hfactor;
    camera.top = hHeight * hfactor;
    camera.bottom = -hHeight * hfactor;

    camera.updateProjectionMatrix();
  }

  setPixelRatio() {
    const { state } = this;

    this.transitionRenderTarget1.setSize(
      state.pixelRatio * window.innerWidth,
      state.pixelRatio * window.innerHeight,
    );

    this.transitionRenderTarget2.setSize(
      state.pixelRatio * window.innerWidth,
      state.pixelRatio * window.innerHeight,
    );

    if (this.renderTarget)
      this.renderTarget.setSize(
        state.pixelRatio * window.innerWidth,
        state.pixelRatio * window.innerHeight,
      );

    if (!this.composed) this.renderer.setPixelRatio(state.pixelRatio);
  }

  // Event listeners
  onDocumentMouseMove(event) {
    tmouseX = event.clientX - window.innerWidth / 2;
    tmouseY = event.clientY - window.innerHeight / 2;
  }

  onDocumentTouchMove(event) {
    event.preventDefault();

    let touchEvent = event.touches[0];

    tmouseX = (touchEvent.clientX - window.innerWidth / 2) * 2.0;
    tmouseY = touchEvent.clientY - window.innerHeight / 2;
  }

  // State
  injectState(inState) {
    this.state = inState;
  }

  readState(inState) {
    const badNames = [];
    const recursiveStateUpdate = (inState, toState) => {
      if (Array.isArray(toState)) {
        for (let i = 0; i < toState.length; i++) {
          if (typeof toState[i] === "object")
            recursiveStateUpdate(inState[i], toState[i]);
          else toState[i] = inState[i];
        }
      } else {
        Object.keys(toState).forEach((key) => {
          if (badNames.indexOf(key) !== -1) return;
          if (
            typeof inState[key] !== "undefined" &&
            typeof inState[key] !== "object"
          ) {
            toState[key] = inState[key];
          } else if (inState[key] && typeof inState[key] === "object") {
            recursiveStateUpdate(inState[key], toState[key]);
          }
        });
      }
    };

    recursiveStateUpdate(inState, this.state);
    this.setParallax();
    this.setPlaneScale();
    this.setBonusMode();
    this.setNumLayers();
    this.setGrain();
    this.setBackground();
  }

  //Video
  playVideo() {
    const { dvideos } = this;
    const dvideo = dvideos[this.to];
    dvideo.play();
  }

  pauseVideo() {
    const { dvideos } = this;
    const dvideo = dvideos[this.to];
    dvideo.pause();
  }

  setVideoTime(time) {
    const { dvideos } = this;
    const dvideo = dvideos[this.to];

    dvideo.currentTime = time * dvideo.duration;
  }

  setUpAndLoadVideo(name) {
    const { dvideo, dvideo2 } = this;
    dvideo.muted = true;
    dvideo.src = "videos/" + name + "/video.mp4";
    dvideo.setAttribute("type", "video/mp4");
    dvideo.playsInline = true;
    dvideo.loop = false;
    dvideo.load();

    this.vdtex = new THREE.VideoTexture(dvideo);

    dvideo2.muted = true;
    dvideo2.src = "videos/" + name + "/video.mp4";
    dvideo2.setAttribute("type", "video/mp4");
    dvideo2.playsInline = true;
    dvideo2.loop = false;
    dvideo2.load();

    this.vdtex2 = new THREE.VideoTexture(dvideo2);

    this.vdtexs = [this.vdtex, this.vdtex2];
  }

  //Plane
  setParallax() {
    const { plane, state } = this;
    plane.material.defines.PARALLAX_TYPE = parallaxDict[state.parallaxType];
    plane.material.needsUpdate = true;
  }

  setPlaneScale() {
    const { plane, state } = this;
    plane.scale.set(state.scale, state.scale, state.scale);
  }

  setBonusMode() {
    const { plane, state } = this;
    plane.material.defines["BONUS_MODE"] = state.bonusMode;
    plane.material.needsUpdate = true;
  }
  setNumLayers() {
    const { plane, state } = this;
    plane.material.defines["NUM_LAYERS"] = state.numLayers;
    plane.material.needsUpdate = true;
  }

  setGrain() {
    const { plane, state } = this;
    plane.material.defines["GRAIN"] = state.grain;
    plane.material.needsUpdate = true;
  }

  setMouseOffset(mX, mY) {
    this.mouseX = tmouseX + (this.state.reverseX ? -mX : mX);
    this.mouseY = tmouseY + (this.state.reverseY ? -mY : mY);
  }

  getTimeDelta() {
    return Math.abs(
      this.endTargetTransitionStructure.time - this.endTransitionStructure.time,
    );
  }

  //Scene
  setBackground() {
    this.scene.background = new THREE.Color(this.state.background);
  }

  initializeScene() {
    this.scene = new THREE.Scene();
    this.setBackground();

    const hHeight = 0.5;
    const hWidth = (window.innerWidth * hHeight) / window.innerHeight;
    hfactor = window.innerWidth / window.innerHeight / 2.1;
    hfactor =
      1.0 -
      (hfactor > 1.0
        ? this.state.fovModifierWide * hfactor
        : hfactor < 0.5
        ? -this.state.fovModifierNarrow * hfactor
        : 0.0);

    this.camera = new THREE.OrthographicCamera(
      -hWidth * hfactor,
      hWidth * hfactor,
      hHeight * hfactor,
      -hHeight * hfactor,
      -10,
      10,
    );

    const scale = 1.2;
    this.plane = new THREE.Mesh(
      new THREE.PlaneGeometry(
        (scale * 2 * 16 * hHeight) / 9,
        scale * 2 * hHeight,
      ),
      texMaterial(),
    );
    this.plane.frustumCulled = false;

    this.setParallax();
    this.setNumLayers();
    this.setGrain();
    this.scene.add(this.plane);

    this.taa = new TaaModule(this.vdtex);
    this.taa2 = new TaaModule(this.vdtex2);
    this.taas = [this.taa, this.taa2];

    this.transitionScene = new THREE.Scene();
    this.transitionPlane = new THREE.Mesh(
      new THREE.PlaneGeometry(1, 1),
      transitionMaterial(),
    );
    this.transitionPlane.frustumCulled = false;
    this.transitionScene.add(this.transitionPlane);
    this.transitionCamera = new THREE.PerspectiveCamera();
  }

  renderTransition(fromtexture, totexture, renderTarget) {
    const { state, plane, renderer, transitionScene, transitionCamera } = this;

    this.transitionPlane.material.uniforms.tex1.value = fromtexture;
    this.transitionPlane.material.uniforms.tex2.value = totexture;
    this.transitionPlane.material.uniforms.reverse.value = 1;

    this.transitionPlane.material.uniforms.transition.value =
      this.endTransitionStructure.transition;
    this.transitionPlane.material.uniforms.stretch.value =
      this.endTransitionStructure.stretch;
    this.transitionPlane.material.uniforms.time.value = this.time;
    this.transitionPlane.material.uniforms.innerTimeMultiplier.value =
      state.innerTimeMultiplier;
    this.transitionPlane.material.uniforms.stretchMultiplier.value.set(
      state.stretchMultiplier.x,
      state.stretchMultiplier.y,
    );

    renderer.setRenderTarget(renderTarget);
    renderer.clear();
    renderer.clearDepth();
    renderer.render(transitionScene, transitionCamera);
  }

  renderVideo(dt, taa, vdtex, renderTarget) {
    const { state, plane, renderer, scene, camera } = this;
    // taa.render(dt, state, renderer);
    plane.material.uniforms.dtex.value = state.usetaa
      ? taa.getTexture()
      : vdtex;

    renderer.setRenderTarget(renderTarget);
    renderer.clear();
    renderer.clearDepth();
    renderer.render(scene, camera);
  }

  swap() {
    this.from++;
    this.to++;

    this.from = this.from % 2;
    this.to = this.to % 2;
  }

  startTransition() {
    this.endTransitionStructure.time = 0;
    this.transitionRunning = true;
    this.swap();

    const dvideo = this.dvideos[this.to];
    dvideo.currentTime = 0;
    dvideo.play();
  }

  animate(DT, useRenderTarget) {
    if (!this.composed)
      requestAnimationFrame(() => {
        this.animate();
      });

    const dt = DT ? DT : Math.min(Date.now() - prev, 60);
    this.dt = dt;
    this.rotation +=
      (dt / 8) * 0.0012 * this.state.backgroundRotationVelocity * Math.PI;
    this.rotation = this.rotation % (2 * Math.PI);

    this.mouseX += 0.015 * (dt / 8) * (tmouseX - this.mouseX);
    this.mouseY += 0.015 * (dt / 8) * (tmouseY - this.mouseY);

    //Transition for the end of the video
    this.time += (30.0 * dt) / 8;
    this.endTransitionStructure.time +=
      this.state.transitionVelocity *
      (dt / 8) *
      (this.endTargetTransitionStructure.time -
        this.endTransitionStructure.time);

    const finalTime = this.endTransitionStructure.time;

    this.endTransitionStructure.transition = getTransition(
      finalTime,
      this.state,
    );
    this.endTransitionStructure.stretch = getStretch(
      this.endTransitionStructure.transition,
      this.state,
    );

    const { state, dvideo, plane, stats, taa, renderer, scene, camera } = this;

    let { mouseX, mouseY } = this;

    if (state.useGyro) {
      mouseX -= 3 * window.innerWidth * this.deviceMotion; // + window.innerWidth / 2;
    }

    const currentDvideo = this.dvideos[this.to];
    state.runningTime = currentDvideo.currentTime / currentDvideo.duration;

    if (!this.composed) stats.update();
    plane.material.uniforms.mouse.value.set(
      (state.reverseX ? -mouseX : mouseX) / (window.innerWidth / 2),
      (state.reverseY ? -mouseY : mouseY) / (window.innerHeight / 2),
    );
    plane.material.uniforms.time.value = performance.now();
    plane.material.uniforms.cropy.value = state.cropy;
    plane.material.uniforms.depthScale.value = state.depthScale;
    plane.material.uniforms.depthThreshold.value = state.depthThreshold;

    plane.material.uniforms.nearestDepth.value = state.nearestDepth;
    plane.material.uniforms.velx.value = state.velx;
    plane.material.uniforms.vely.value = state.vely;
    plane.material.uniforms.rotation.value = this.rotation;
    plane.material.uniforms.scaleRotation.value =
      this.state.backgroundRotationScale;

    const transitionRunning = this.getTimeDelta() > 0.15;

    if (state.usetaa) this.taas[this.to].render(dt, state, renderer);
    // console.log(this.getTimeDelta());

    if (transitionRunning) {
      if (state.usetaa) this.taas[this.from].render(dt, state, renderer);

      this.renderVideo(dt, this.taa, this.vdtex, this.transitionRenderTarget1);
      this.renderVideo(
        dt,
        this.taa2,
        this.vdtex2,
        this.transitionRenderTarget2,
      );

      //Render Transition pass
      if (this.composed && useRenderTarget)
        this.renderTransition(
          this.transitionRenderTargets[this.from].texture,
          this.transitionRenderTargets[this.to].texture,
          this.renderTarget,
        );
      else
        this.renderTransition(
          this.transitionRenderTargets[this.from].texture,
          this.transitionRenderTargets[this.to].texture,
          null,
        );
    } else {
      this.transitionRunning = false;
      if (this.composed && useRenderTarget)
        this.renderVideo(
          dt,
          this.taas[this.to],
          this.vdtexs[this.to],
          this.renderTarget,
        );
      else this.renderVideo(dt, this.taas[this.to], this.vdtexs[this.to], null);
    }

    if (state.runningTime > state.finishTime && !this.transitionRunning) {
      this.startTransition();
    }

    prev = Date.now();
  }
}

import * as THREE from "three";
import vertex from "./taa.glslv";
import fragment from "./transition.glslf";

const transitionMaterial = () => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    transparent: true,
    blending: THREE.NormalBlending,
    // blending: THREE.AdditiveBlending,
    uniforms: {
      tex1: { value: null },
      tex2: { value: null },
      transition: { value: 0 },
      stretch: { value: 0 },
      stretchMultiplier: { value: new THREE.Vector2(0.01, 1.0) },
      innerTimeMultiplier: { value: 0.0002 },
      time: { value: 0 },
      reverse: { value: 1 },
    },
    defines: {},
  });
};

export default transitionMaterial;

import * as THREE from "three";
import vertex from "./cardBackground.glslv";
import fragment from "./cardBackground.glslf";

const cardBackgroundMaterial = () => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    transparent: true,
    depthTest: false,
    depthWrite: false,
    side: THREE.DoubleSide,
    uniforms: {
      mouse: { value: new THREE.Vector2() },
      // globalOffset: { value: new THREE.Vector3(0, 0, 0) },
      s: { value: 0 },
      tex: { value: null },
      rotation: { value: 0 },
      scaleRotation: { value: 1 },
    },
    defines: {},
  });
};

export default cardBackgroundMaterial;

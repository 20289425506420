import * as THREE from "three";
import vertex from "./cardborder.glslv";
import fragment from "./cardline.glslf";

const cardborderMaterial = () => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    // transparent: true,
    depthTest: false,
    depthWrite: true,
    // transparent: true,
    uniforms: {
      mouse: { value: new THREE.Vector2() },
      s: { value: 1.0 },
      rotation: { value: 0 },
      scaleRotation: { value: 1 },
      // globalOffset: { value: new THREE.Vector2(0.0, 0.0) },
    },
    defines: {
      UP: true,
    },
  });
};

export default cardborderMaterial;

import * as THREE from "three";
import vertex from "./tex.glslv";
import fragment from "./tex.glslf";

const texMaterial = () => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    // transparent: true,
    blending: THREE.NormalBlending,
    // blending: THREE.AdditiveBlending,
    uniforms: {
      dtex: { value: null },
      cropy: { value: 0.0 },
      depthScale: { value: 0.0 },
      mouse: { value: new THREE.Vector2() },
      time: { value: 0 },
      velx: { value: 0 },
      vely: { value: 0 },
      nearestDepth: { value: 0 },
      depthThreshold: { value: 1 },
      rotation: { value: 0 },
      scaleRotation: { value: 1 },
    },
    defines: {
      PARALLAX_TYPE: 1,
      NUM_LAYERS: 32,
      GRAIN: false,
      BONUS_MODE: false,
    },
  });
};

export default texMaterial;

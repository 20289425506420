import * as THREE from "three";

import texMaterial from "../../materials/tex2";
import taaMaterial from "../../materials/taa";

let tmouseX = 0;
let tmouseY = 0;

const tloader = new THREE.TextureLoader();
// const chosenImage = IMAGES[0];

const parallaxDict = {
  steep: 3,
  occlusion_mapping: 1,
  original: 2,
};

let prev = Date.now();
let hfactor = 1;

const globalTextureData = {
  loadedd: ((toAdd) => {
    toAdd["loaded"] = null;
    return toAdd;
  })(
    IMAGES.reduce((a, c) => {
      const p = { ...a };
      p[c] = null;
      return p;
    }, {}),
  ),
  loadedcd: ((toAdd) => {
    toAdd["loaded"] = null;
    return toAdd;
  })(
    IMAGES.reduce((a, c) => {
      const p = { ...a };
      p[c] = null;
      return p;
    }, {}),
  ),
  loadedc: ((toAdd) => {
    toAdd["loaded"] = null;
    return toAdd;
  })(
    IMAGES.reduce((a, c) => {
      const p = { ...a };
      p[c] = null;
      return p;
    }, {}),
  ),
};

export function getGlobalTextureData() {
  return globalTextureData;
}

export function loadTexture(url, callback) {
  tloader.load(url, callback);
}

export default class ImageApp {
  constructor({ state, composed, renderer, container, stats }) {
    this.state = state;
    this.composed = composed;
    this.type = "image";

    if (stats) {
      this.stats = stats;
      document.body.append(stats.dom);
    }

    if (!composed) {
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setPixelRatio(2.0);
      this.renderer.autoClear = false;

      this.container = document.querySelector(".container");
      this.container.append(this.renderer.domElement);

      window.addEventListener("mousemove", this.onDocumentMouseMove, false);
      window.addEventListener("touchmove", this.onDocumentTouchMove, false);
    } else {
      this.renderer = renderer;
      this.container = container;

      this.renderTarget = new THREE.WebGLRenderTarget(
        window.innerWidth,
        window.innerHeight,
      );
      // this.renderTarget.samples = 4;
    }
    this.rotation = 0;
    this.mouseX = 0;
    this.mouseY = 0;

    this.deviceMotion = 0;
    // if (
    //   DeviceMotionEvent &&
    //   typeof DeviceMotionEvent.requestPermission === "function"
    // ) {
    //   DeviceMotionEvent.requestPermission();
    // }

    window.addEventListener("devicemotion", (event) => {
      if (!this.dt) return;
      const velocity =
        window.innerHeight > window.innerWidth
          ? event.rotationRate.beta
          : event.rotationRate.alpha;

      const target = velocity > 0.0 ? 1.0 : -1.0;
      this.deviceMotion +=
        (target - this.deviceMotion) *
        (Math.abs(velocity) / 180) *
        this.state.gyroVelocity *
        (this.dt / 16);
      // this.deviceMotion = Math.min(1.0, Math.max(-1.0, this.deviceMotion));
    });
    // window.addEventListener("deviceorientation", handleOrientation);

    window.addEventListener("resize", () => {
      const hHeight = 0.5;
      const hWidth = (window.innerWidth * hHeight) / window.innerHeight;

      hfactor = window.innerWidth / window.innerHeight / 2.1;
      hfactor =
        1.0 -
        (hfactor > 1.0
          ? this.state.fovModifierWide * hfactor
          : hfactor < 0.5
          ? -this.state.fovModifierNarrow * hfactor
          : 0.0);

      const camera = this.camera;
      camera.left = -hWidth * hfactor;
      camera.right = hWidth * hfactor;
      camera.top = hHeight * hfactor;
      camera.bottom = -hHeight * hfactor;

      camera.updateProjectionMatrix();

      const scale = 1.2;
      const dimensions = this.getSizes(this.textureData.cname.split("/")[0]);
      this.imageScale = new THREE.Vector2(
        (scale * 2 * dimensions.width * hHeight) / dimensions.height,
        scale * 2 * hHeight,
      );
      this.setPlaneScale();

      this.renderer.setSize(window.innerWidth, window.innerHeight);
    });
  }

  setHfactor() {
    const hHeight = 0.5;
    const hWidth = (window.innerWidth * hHeight) / window.innerHeight;

    hfactor = window.innerWidth / window.innerHeight / 2.1;
    hfactor =
      1.0 -
      (hfactor > 1.0
        ? this.state.fovModifierWide * hfactor
        : hfactor < 0.5
        ? -this.state.fovModifierNarrow * hfactor
        : 0.0);

    const camera = this.camera;
    camera.left = -hWidth * hfactor;
    camera.right = hWidth * hfactor;
    camera.top = hHeight * hfactor;
    camera.bottom = -hHeight * hfactor;

    camera.updateProjectionMatrix();
  }

  setPixelRatio() {
    const { state } = this;

    if (this.renderTarget)
      this.renderTarget.setSize(
        state.pixelRatio * window.innerWidth,
        state.pixelRatio * window.innerHeight,
      );

    if (!this.composed) this.renderer.setPixelRatio(state.pixelRatio);
  }

  getSizes(name) {
    if (name == "loaded")
      return {
        width: this.state.loadedWidth,
        height: this.state.loadedHeight,
      };
    else return SIZES[name];
  }

  injectState(inState) {
    this.state = inState;
  }

  readState(inState) {
    const badNames = [];
    const recursiveStateUpdate = (inState, toState) => {
      if (Array.isArray(toState)) {
        for (let i = 0; i < toState.length; i++) {
          if (typeof toState[i] === "object")
            recursiveStateUpdate(inState[i], toState[i]);
          else toState[i] = inState[i];
        }
      } else {
        Object.keys(toState).forEach((key) => {
          if (badNames.indexOf(key) !== -1) return;
          if (
            typeof inState[key] !== "undefined" &&
            typeof inState[key] !== "object"
          ) {
            toState[key] = inState[key];
          } else if (inState[key] && typeof inState[key] === "object") {
            recursiveStateUpdate(inState[key], toState[key]);
          }
        });
      }
    };

    recursiveStateUpdate(inState, this.state);
    this.setParallax();
    this.setPlaneScale();
    this.setBonusMode();
    this.setNumLayers();
    this.setGrain();
    this.setBackground();
    this.updateMaterialRegime();
    this.updateMaterialTexture();
  }

  onDocumentMouseMove(event) {
    tmouseX = event.clientX - window.innerWidth / 2;
    tmouseY = event.clientY - window.innerHeight / 2;
  }

  onDocumentTouchMove(event) {
    event.preventDefault();

    let touchEvent = event.touches[0];

    tmouseX = (touchEvent.clientX - window.innerWidth / 2) * 2.0;
    tmouseY = touchEvent.clientY - window.innerHeight / 2;
  }

  //Plane setting

  setParallax() {
    const { plane, state } = this;
    plane.material.defines.PARALLAX_TYPE = parallaxDict[state.parallaxType];
    plane.material.needsUpdate = true;
  }

  setPlaneScale() {
    const { plane, state } = this;
    plane.scale.set(
      state.scale * this.imageScale.x,
      state.scale * this.imageScale.y,
      state.scale,
    );
  }

  setBonusMode() {
    const { plane, state } = this;
    plane.material.defines["BONUS_MODE"] = state.bonusMode;
    plane.material.needsUpdate = true;
  }

  setNumLayers() {
    const { plane, state } = this;
    plane.material.defines["NUM_LAYERS"] = state.numLayers;
    plane.material.needsUpdate = true;
  }

  setGrain() {
    const { plane, state } = this;
    plane.material.defines["GRAIN"] = state.grain;
    plane.material.needsUpdate = true;
  }

  setBackground() {
    const { scene, state } = this;
    scene.background = new THREE.Color(state.background);
  }

  updateMaterialTexture() {
    const { plane, state, textureData } = this;

    const scale = 1.2;
    const dimensions = this.getSizes(textureData.cname.split("/")[0]);

    const hHeight = 0.5;
    const hWidth = (window.innerWidth * hHeight) / window.innerHeight;
    this.imageScale = new THREE.Vector2(
      (scale * 2 * dimensions.width * hHeight) / dimensions.height,
      scale * 2 * hHeight,
    );
    this.setPlaneScale();

    if (state.regime == "merged") {
      plane.material.uniforms.dtex.value = textureData.vcdtex;
    } else {
      plane.material.uniforms.ctex.value = textureData.vctex;
      plane.material.uniforms.dtex.value = textureData.vdtex;
    }
  }

  updateMaterialRegime() {
    const { plane, state } = this;
    plane.material.defines.SEPARATE = state.regime === "separate";
    plane.material.needsUpdate = true;

    this.updateMaterialTexture();
  }

  setMouseOffset(mX, mY) {
    this.mouseX = tmouseX + (this.state.reverseX ? -mX : mX);
    this.mouseY = tmouseY + (this.state.reverseY ? -mY : mY);
  }

  getTextureData() {
    return this.textureData;
  }

  setColorDepthTexture(name) {
    const { textureData } = this;
    if (name == "loaded") {
      if (!globalTextureData.loadedcd["loaded"]) {
        console.log("No loaded texture");
        return;
      }

      textureData.vcdtex = globalTextureData.loadedc["loaded"];
      this.updateMaterialTexture();
    } else {
      if (!globalTextureData.loadedcd[name]) {
        const chosenFormat = FORMATS[name];
        tloader.load("images/" + name + "/cd." + chosenFormat, (texture) => {
          textureData.vcdtex = texture;
          globalTextureData.loadedcd[name] = texture;
          this.updateMaterialTexture();
        });
      } else {
        textureData.vcdtex = globalTextureData.loadedcd[name];
        this.updateMaterialTexture();
      }
    }
  }

  setDepthTexture(name) {
    const { textureData } = this;

    if (name == "loaded") {
      if (!globalTextureData.loadedd["loaded"]) {
        console.log("No loaded texture");
        return;
      }

      textureData.vdtex = globalTextureData.loadedd["loaded"];
      this.updateMaterialTexture();
    } else {
      if (!globalTextureData.loadedd[name]) {
        const chosenFormat = FORMATS[name];
        tloader.load("images/" + name + "/d." + chosenFormat, (texture) => {
          textureData.vdtex = texture;
          globalTextureData.loadedd[name] = texture;
          this.updateMaterialTexture();
        });
      } else {
        textureData.vdtex = globalTextureData.loadedd[name];
        this.updateMaterialTexture();
      }
    }
  }

  setColorTexture(name) {
    const { textureData } = this;

    if (name == "loaded") {
      if (!globalTextureData.loadedc["loaded"]) {
        console.log("No loaded texture");
        return;
      }

      textureData.vctex = globalTextureData.loadedc["loaded"];
      this.updateMaterialTexture();
    } else {
      if (!globalTextureData.loadedc[name]) {
        const chosenFormat = FORMATS[name];
        tloader.load("images/" + name + "/c." + chosenFormat, (texture) => {
          textureData.vctex = texture;
          globalTextureData.loadedc[name] = texture;
          this.updateMaterialTexture();
        });
      } else {
        textureData.vctex = globalTextureData.loadedc[name];
        this.updateMaterialTexture();
      }
    }
  }

  initializeScene() {
    this.scene = new THREE.Scene();
    // scene.background = new THREE.Color("#030637");
    this.setBackground();
    const hHeight = 0.5;
    const hWidth = (window.innerWidth * hHeight) / window.innerHeight;

    hfactor = window.innerWidth / window.innerHeight / 2.1;
    hfactor =
      1.0 -
      (hfactor > 1.0
        ? this.state.fovModifierWide * hfactor
        : hfactor < 0.5
        ? -this.state.fovModifierNarrow * hfactor
        : 0.0);

    this.camera = new THREE.OrthographicCamera(
      -hWidth * hfactor,
      hWidth * hfactor,
      hHeight * hfactor,
      -hHeight * hfactor,
      -10,
      10,
    );
    // controls = new OrbitControls(camera, renderer.domElement);

    this.plane = new THREE.Mesh(new THREE.PlaneGeometry(1, 1), texMaterial());

    this.plane.material.uniforms.cropy.value = 0.1;
    this.plane.material.defines.SEPARATE = this.state.regime == "separate";
    this.setParallax();
    this.plane.frustumCulled = false;
    this.setNumLayers();
    this.setGrain();
    this.scene.add(this.plane);

    // console.log(textureData);
    const chosenImage = this.state.startImage;

    this.textureData = {
      cdname: chosenImage + "/cd",
      cname: chosenImage + "/c",
      dname: chosenImage + "/d",

      vdtex: null,
      vctex: null,
      vcdtex: null,
    };

    const scale = 1.2;
    const dimensions = this.getSizes(chosenImage);

    this.imageScale = new THREE.Vector2(
      (scale * 2 * dimensions.width * hHeight) / dimensions.height,
      scale * 2 * hHeight,
    );
    this.plane.scale.set(this.imageScale.x, this.imageScale.y);

    const chosenFormat = FORMATS[chosenImage];

    tloader.load("images/" + chosenImage + "/cd." + chosenFormat, (texture) => {
      this.textureData.vcdtex = texture;
      globalTextureData.loadedcd[chosenImage] = texture;
      this.updateMaterialRegime();
    });
    tloader.load("images/" + chosenImage + "/c." + chosenFormat, (texture) => {
      this.textureData.vctex = texture;
      globalTextureData.loadedc[chosenImage] = texture;
      this.updateMaterialRegime();
    });
    tloader.load("images/" + chosenImage + "/d." + chosenFormat, (texture) => {
      this.textureData.vdtex = texture;
      globalTextureData.loadedd[chosenImage] = texture;
      this.updateMaterialRegime();
    });
  }

  animate(DT, useRenderTarget) {
    if (!this.composed)
      requestAnimationFrame(() => {
        this.animate();
      });

    const dt = DT ? DT : Math.min(Date.now() - prev, 60);
    this.dt = dt;
    this.rotation +=
      (dt / 8) * 0.0012 * this.state.backgroundRotationVelocity * Math.PI;
    this.rotation = this.rotation % (2 * Math.PI);

    this.mouseX += 0.015 * (dt / 8) * (tmouseX - this.mouseX);
    this.mouseY += 0.015 * (dt / 8) * (tmouseY - this.mouseY);

    const { stats, plane, renderer, camera, scene, state } = this;

    let { mouseX, mouseY } = this;

    if (state.useGyro) {
      mouseX -= 3 * window.innerWidth * this.deviceMotion; // + window.innerWidth / 2;
    }
    // mouseY +=
    //   3 * window.innerHeight * Math.sin((Math.PI * this.deviceMotion.y) / 180);

    if (!this.composed) stats.update();
    plane.material.uniforms.mouse.value.set(
      (state.reverseX ? -mouseX : mouseX) / (window.innerWidth / 2),
      (state.reverseY ? -mouseY : mouseY) / (window.innerHeight / 2),
    );

    // plane.material.uniforms.time.value = performance.now();
    // plane.material.uniforms.dtex.value = taa.getTexture();
    plane.material.uniforms.cropy.value = state.cropy;
    plane.material.uniforms.depthScale.value = state.depthScale;
    plane.material.uniforms.depthThreshold.value = state.depthThreshold;

    plane.material.uniforms.nearestDepth.value = state.nearestDepth;
    plane.material.uniforms.velx.value = state.velx;
    plane.material.uniforms.vely.value = state.vely;

    plane.material.uniforms.rotation.value = this.rotation;
    plane.material.uniforms.scaleRotation.value =
      this.state.backgroundRotationScale;

    if (this.composed && useRenderTarget)
      renderer.setRenderTarget(this.renderTarget);
    else renderer.setRenderTarget(null);

    renderer.clear();
    renderer.clearDepth();
    renderer.render(scene, camera);
    prev = Date.now();
  }
}

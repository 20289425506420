import * as THREE from "three";
import vertex from "./wireframe.glslv";
import fragment from "./wireframe.glslf";

const wireframeMaterial = () => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    // transparent: true,
    wireframe: true,
    // blending: THREE.NormalBlending,
    // blending: THREE.AdditiveBlending,
    depthTest: true,
    depthWrite: true,
    transparent: true,
    uniforms: {
      mouse: { value: new THREE.Vector2() },
      globalOffset: { value: new THREE.Vector3(0, 0, 0) },
      s: { value: 0 },
	    rotation: { value: 0 },
	    scaleRotation: { value: 1 }
    },
    defines: {
	CAR: false
    },
  });
};

export default wireframeMaterial;

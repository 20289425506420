import * as THREE from "three";
import vertex from "./taa.glslv";
import fragment from "./taa.glslf";

const taaMaterial = () => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    transparent: true,
    blending: THREE.NormalBlending,
    // blending: THREE.AdditiveBlending,
    uniforms: {
      dtex: { value: null },
      taaFactor: { value: 0.035 },
      time: { value: 0 },
      depthGrain: { value: 0 },
    },
    defines: {},
  });
};

export default taaMaterial;

import * as THREE from "three";
import vertex from "./buildPlaneMaterial.glslv";
import fragment from "./buildPlaneMaterial.glslf";

const buildPlaneMaterial = () => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    transparent: true,
    // blending: THREE.NormalBlending,
    // blending: THREE.AdditiveBlending,
    depthTest: true,
    depthWrite: true,
    transparent: true,
    uniforms: {
      mouse: { value: new THREE.Vector2() },
      s: { value: 0 },
      tex: { value: null },
      rotation: { value: 0 },
      scaleRotation: { value: 1 },
    },
    defines: {
      UPSIDEDOWN: false,
    },
  });
};

export default buildPlaneMaterial;

import * as THREE from "three";
import vertex from "./cardline.glslv";
import fragment from "./cardline.glslf";

const cardlineMaterial = () => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    // transparent: true,
    depthTest: false,
    depthWrite: true,
    // transparent: true,
    uniforms: {
      mouse: { value: new THREE.Vector2() },
      s: { value: 1.0 },
      height: { value: 0.2 },
      offset: { value: new THREE.Vector2(0.2, 0.1) },
      rotation: { value: 0 },
      scaleRotation: { value: 1 },
    },
    defines: {},
  });
};

export default cardlineMaterial;

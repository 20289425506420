import * as THREE from "three";
import vertex from "./roadline.glslv";
import fragment from "./cardline.glslf";

const roadlineMaterial = () => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    depthTest: false,
    depthWrite: true,
    // transparent: true,
    uniforms: {
      mouse: { value: new THREE.Vector2() },
      s: { value: 1.0 },
      rotation: { value: 0 },
      scaleRotation: { value: 1 },
      // globalOffset: { value: new THREE.Vector2(0.0, 0.0) },
    },
    defines: {
      LEFT: true,
    },
  });
};

export default roadlineMaterial;

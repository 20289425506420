import * as THREE from "three";
import HudApp from "../hudvideo/module";
import ImageApp from "../image/module";
import VideoApp from "../video/module";
import transitionMaterial from "../../materials/transition";
import { easings } from "../../easing";

const appCreators = {
  hud: HudApp,
  image: ImageApp,
  video: VideoApp,
};

const power = 1.0;
const amplitude = 0.95;
const velocity = 0.008;

function getStretch(time, state) {
  return state.stretchAmplitude * Math.pow(Math.sin(Math.PI * time), power);
}
function getTransition(time, state) {
  return easings[state.transitionEasing](time);
}

export default class SliderApp {
  constructor(appDescriptions, transitionState, stats) {
    this.renderer = new THREE.WebGLRenderer({ antialias: ANTIALIAS });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setPixelRatio(1.5);
    this.renderer.autoClear = false;

    this.container = document.querySelector(".container");
    this.container.append(this.renderer.domElement);

    if (stats) {
      this.stats = stats;
      document.body.append(this.stats.dom);
    }

    // window.addEventListener("keydown", (e) => {
    //   if (e.code == "KeyP") {
    //     this.playVideo();
    //   }
    //   if (e.code == "KeyO") {
    //     this.pauseVideo();
    //   }
    // });
    this.apps = [];
    this.states = [];

    appDescriptions.forEach((description) => {
      this.apps.push(
        new appCreators[description.type]({
          state: description.state,
          composed: true,
          renderer: this.renderer,
          container: this.container,
        }),
      );
      this.states.push(description.state);
    });

    window.addEventListener(
      "mousemove",
      (event) => {
        this.apps.forEach((app) => {
          app.onDocumentMouseMove(event);
        });
      },
      false,
    );
    window.addEventListener(
      "touchmove",
      (event) => {
        this.apps.forEach((app) => {
          app.onDocumentTouchMove(event);
        });
      },
      false,
    );

    this.scene = new THREE.Scene();
    this.plane = new THREE.Mesh(
      new THREE.PlaneGeometry(1, 1),
      transitionMaterial(),
    );
    this.plane.frustumCulled = false;
    this.scene.add(this.plane);

    this.fakeCamera = new THREE.PerspectiveCamera();

    this.time = 0;
    this.prev = Date.now();
    this.easing = easings["quarticOut"];

    this.transitionStructure = {
      transition: 0,
      stretch: 0,
      time: 1,
    };

    this.targetTransitionStructure = {
      transition: 0,
      stretch: 0,
      time: 1,
    };

    this.from = this.apps.length - 1;
    this.to = 0;
    this.reverse = 1.0;

    this.transitionState = transitionState;
  }

  updateTransitionState(inTransitionState) {
    const badNames = ["transitionTime"];
    const recursiveStateUpdate = (inState, toState) => {
      if (Array.isArray(toState)) {
        for (let i = 0; i < toState.length; i++) {
          if (typeof toState[i] === "object")
            recursiveStateUpdate(inState[i], toState[i]);
          else toState[i] = inState[i];
        }
      } else {
        Object.keys(toState).forEach((key) => {
          if (badNames.indexOf(key) !== -1) return;
          if (
            typeof inState[key] !== "undefined" &&
            typeof inState[key] !== "object"
          ) {
            toState[key] = inState[key];
          } else if (inState[key] && typeof inState[key] === "object") {
            recursiveStateUpdate(inState[key], toState[key]);
          }
        });
      }
    };

    recursiveStateUpdate(inTransitionState, this.transitionState);
  }

  setPixelRatio(pr) {
    this.renderer.setPixelRatio(pr);
  }

  injectState(states) {
    this.states = states;
    this.apps.forEach((app, index) => {
      app.injectState(states[index]);
    });
  }
  initializeScene() {
    this.apps.forEach((app) => {
      app.initializeScene();
    });
  }

  getTimeDelta() {
    return Math.abs(
      this.targetTransitionStructure.time - this.transitionStructure.time,
    );
  }

  playVideo() {
    if (this.apps[this.to].playVideo) this.apps[this.to].playVideo();
  }
  pauseVideo() {
    if (this.apps[this.to].pauseVideo) this.apps[this.to].pauseVideo();
  }

  goToSlide(index, reverse = 1) {
    if (index < 0 || index >= this.apps.length) return;

    const delta = this.getTimeDelta();
    if (delta < 0.18) {
      this.targetTransitionStructure.time = 1;
      this.transitionStructure.time = 0;

      this.easing = easings["linear"];
      this.reverse = reverse;

      this.from = this.to;
      this.to = index;

      if (!this.transitionState.renderSimultaneously)
        this.apps[this.from].animate(0.0, true);
      this.apps[this.to].setMouseOffset(-window.innerWidth * 2.0, 0.0);

      if (this.transitionState.autoPausePlay) {
        if (this.apps[this.to].playVideo) this.apps[this.to].playVideo();

        setTimeout(() => {
          if (this.apps[this.from].pauseVideo)
            this.apps[this.from].pauseVideo(true);
        }, this.transitionState.pauseTimeout);
      }
    }
  }

  forward() {
    const delta = this.getTimeDelta();
    if (delta < 0.18) {
      this.targetTransitionStructure.time = 1;
      this.transitionStructure.time = 0;

      this.easing = easings["linear"];
      this.reverse = 1.0;

      this.from = this.to;
      this.to = (this.to + 1) % this.apps.length;

      if (!this.transitionState.renderSimultaneously)
        this.apps[this.from].animate(0.0, true);
      this.apps[this.to].setMouseOffset(-window.innerWidth * 2.0, 0.0);

      if (this.transitionState.autoPausePlay) {
        if (this.apps[this.to].playVideo) this.apps[this.to].playVideo();

        setTimeout(() => {
          if (this.apps[this.from].pauseVideo)
            this.apps[this.from].pauseVideo(true);
        }, this.transitionState.pauseTimeout);
      }
    }
  }

  backward() {
    const delta = this.getTimeDelta();
    if (delta < 0.18) {
      this.targetTransitionStructure.time = 1;
      this.transitionStructure.time = 0;

      this.easing = easings["linear"];
      this.reverse = -1.0;

      this.from = this.to;
      this.to = (this.to - 1 + this.apps.length) % this.apps.length;

      if (!this.transitionState.renderSimultaneously)
        this.apps[this.from].animate(0.0, true);
      this.apps[this.to].setMouseOffset(window.innerWidth * 2.0, 0.0);

      if (this.transitionState.autoPausePlay) {
        if (this.apps[this.to].playVideo) this.apps[this.to].playVideo();

        setTimeout(() => {
          if (this.apps[this.from].pauseVideo)
            this.apps[this.from].pauseVideo(true);
        }, this.transitionState.pauseTimeout);
      }
    }
  }

  animate() {
    requestAnimationFrame(() => {
      this.animate();
    });

    const now = Date.now();
    const dt = Math.min(now - this.prev, 60);
    // console.log(this.getTimeDelta());

    if (this.stats) this.stats.update();

    this.time += (30.0 * dt) / 8;
    //0.008
    this.transitionStructure.time +=
      this.transitionState.transitionVelocity *
      (dt / 8) *
      (this.targetTransitionStructure.time - this.transitionStructure.time);

    const finalTime = this.easing(this.transitionStructure.time);

    this.transitionStructure.transition = getTransition(
      finalTime,
      this.transitionState,
    );
    this.transitionStructure.stretch = getStretch(
      this.transitionStructure.transition,
      this.transitionState,
    );

    const from = this.apps[this.from];
    const to = this.apps[this.to];

    const usePost = this.getTimeDelta() > 0.1;

    if (
      this.getTimeDelta() > 0.15 &&
      this.from != this.to &&
      this.transitionState.renderSimultaneously
    )
      from.animate(dt, usePost);
    to.animate(dt, usePost);

    this.plane.material.uniforms.tex1.value = from.renderTarget.texture;
    this.plane.material.uniforms.tex2.value = to.renderTarget.texture;
    this.plane.material.uniforms.reverse.value = this.reverse;

    this.plane.material.uniforms.innerTimeMultiplier.value =
      this.transitionState.innerTimeMultiplier;
    this.plane.material.uniforms.stretchMultiplier.value.set(
      this.transitionState.stretchMultiplier.x,
      this.transitionState.stretchMultiplier.y,
    );
    this.plane.material.uniforms.transition.value =
      this.transitionStructure.transition;
    this.plane.material.uniforms.stretch.value =
      this.transitionStructure.stretch;
    this.plane.material.uniforms.time.value = this.time;

    if (usePost) {
      this.renderer.setRenderTarget(null);
      this.renderer.render(this.scene, this.fakeCamera);
    }

    this.prev = now;
  }
}

window.SliderModule = {
  App: SliderApp,
};

import * as THREE from "three";
import { Text } from "../../../node_modules/troika-three-text/src/index";

export class TextRenderer {
  constructor(text, renderer, size, offset, callback) {
    this.text = text;
    this.renderer = renderer;

    const width = 1024;
    const height = 512;
    const aspect = width / height;

    this.rt = new THREE.WebGLRenderTarget(width, height);
    const hHeight = 2.5;
    this.camera = new THREE.OrthographicCamera(
      -hHeight * aspect,
      hHeight * aspect,
      hHeight,
      -hHeight,
      -2,
      2,
    );

    // this.camera = new THREE.PerspectiveCamera(60, aspect, 0.1, 100);

    this.scene = new THREE.Scene();

    const p = new THREE.Mesh(
      new THREE.PlaneGeometry(1, 1),
      new THREE.MeshBasicMaterial(),
    );

    // this.scene.add(p);
    this.scene.background = new THREE.Color("#ffffff");
    // this.scene.background = new THREE.Color("#000000");

    const myText = new Text();
    this.scene.add(myText);

    // Set properties to configure:
    myText.text = this.text;
    myText.name = this.text;
    myText.font = "Anta-Regular.ttf";
    // myText.font = "CRYSISB.ttf";
    myText.fontSize = size;
    myText.fontWeight = "bold";
    myText.position.y = offset;
    myText.letterSpacing = 0.15;
    // myText.color = 0xffffff;
    myText.color = 0x000000;
    myText.anchorX = "center";
    myText.textAlign = "center";

    this.textMesh = myText;
    // Update the rendering:
    myText.sync(callback);
  }

  isSyncing() {
    return this.textMesh._isSyncing;
  }

  render() {
    // this.textMesh.sync();

    this.renderer.setRenderTarget(this.rt);
    this.renderer.render(this.scene, this.camera);
    this.renderer.setRenderTarget(null);
  }
  get() {
    return this.rt.texture;
  }
}

import * as THREE from "three";
import device from "current-device";

import texMaterial from "../../materials/tex";
import { buildGUI } from "./gui";
import taaMaterial from "../../materials/taa";
import wireframeMaterial from "../../materials/wireframe";
import tex2Material from "../../materials/tex2";
import buildPlaneMaterial from "../../materials/buildPlaneMaterial";
import cardlineMaterial from "../../materials/lines/cardline";
import buildingborderMaterial from "../../materials/lines/buildingborder";
import { Card } from "./card";
import roadlineMaterial from "../../materials/lines/roadline";
import transitionMaterial from "../../materials/transition";
import { TextRenderer } from "./text";
import { easings } from "../../easing";

//Globals
const raycaster = new THREE.Raycaster();
const pointer = new THREE.Vector2();

let tmouseX = 0;
let tmouseY = 0;

const parallaxDict = {
  steep: 3,
  occlusion_mapping: 1,
  original: 2,
};

let prev = Date.now();
let hfactor;

const power = 1.0;
const amplitude = 0.95;

function getStretch(time, state) {
  return state.stretchAmplitude * Math.pow(Math.sin(Math.PI * time), power);
}
function getTransition(time, state) {
  return easings[state.transitionEasing](time);
}

class TaaModule {
  constructor(vdtex) {
    this.scene = new THREE.Scene();
    this.plane = new THREE.Mesh(new THREE.PlaneGeometry(1, 1), taaMaterial());
    this.plane.material.uniforms.dtex.value = vdtex;
    this.plane.frustumCulled = false;
    this.scene.add(this.plane);

    this.rt = new THREE.WebGLRenderTarget(1920, 1080 * 2);
    // this.rt.depthTexture = new THREE.DepthTexture(1920, 1080 * 2);
    this.fakeCamera = new THREE.PerspectiveCamera();
  }

  render(dt, state, renderer) {
    this.plane.material.uniforms.taaFactor.value = state.taaFactor * (dt / 8);
    this.plane.material.uniforms.time.value = performance.now();
    this.plane.material.uniforms.depthGrain.value = state.depthGrain;

    const prevAutoClear = renderer.autoClear;
    renderer.autoClear = false;
    renderer.setRenderTarget(this.rt);
    // renderer.clearDepth();
    renderer.render(this.scene, this.fakeCamera);
    // renderer.render(holoScene, holoCamera);
    renderer.setRenderTarget(null);
    renderer.autoClear = prevAutoClear;
  }

  getTexture() {
    return this.rt.texture;
  }
}

export default class HudApp {
  constructor({ state, composed, renderer, container, stats }) {
    this.state = state;
    this.composed = composed;
    this.type = "hud";

    if (stats) {
      this.stats = stats;
      document.body.append(this.stats.dom);
    }

    if (!composed) {
      this.renderer = new THREE.WebGLRenderer({ antialias: ANTIALIAS });
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setPixelRatio(state.pixelRatio);
      this.renderer.autoClear = false;

      this.container = document.querySelector(".container");
      this.container.append(this.renderer.domElement);

      window.addEventListener("mousemove", this.onDocumentMouseMove, false);
      window.addEventListener("touchmove", this.onDocumentTouchMove, false);
    } else {
      this.renderer = renderer;
      this.container = container;

      this.renderTarget = new THREE.WebGLRenderTarget(
        state.pixelRatio * window.innerWidth,
        state.pixelRatio * window.innerHeight,
      );

      // if (!device.mobile() && !device.tablet() && ANTIALIAS) {
      //   this.renderTarget.samples = 4;
      // }
    }

    this.dvideo = document.createElement("video");
    this.dvideo2 = document.createElement("video");
    this.dvideos = [this.dvideo, this.dvideo2];

    this.setUpAndLoadVideo(
      this.state.startVideo ? this.state.startVideo : NAME,
    );
    this.rotation = 0;
    this.mouseX = 0;
    this.mouseY = 0;

    this.transitionRenderTarget1 = new THREE.WebGLRenderTarget(
      state.pixelRatio * window.innerWidth,
      state.pixelRatio * window.innerHeight,
    );
    // if (ANTIALIAS) this.transitionRenderTarget1.samples = 4;

    this.transitionRenderTarget2 = new THREE.WebGLRenderTarget(
      state.pixelRatio * window.innerWidth,
      state.pixelRatio * window.innerHeight,
    );
    // if (ANTIALIAS) this.transitionRenderTarget2.samples = 4;

    this.transitionRenderTargets = [
      this.transitionRenderTarget1,
      this.transitionRenderTarget2,
    ];

    this.time = 0;

    this.endTransitionStructure = {
      transition: 0,
      stretch: 0,
      time: 1,
    };

    this.endTargetTransitionStructure = {
      transition: 0,
      stretch: 0,
      time: 1,
    };

    this.from = 1;
    this.to = 0;

    this.transitionRunning = false;

    this.intersectionPaused = false;
    this.toPlay = false;

    this.deviceMotion = 0;

    window.addEventListener(
      "wheel",
      () => {
        this.onDocumentWheel(this);
      },
      false,
    );
    // window.addEventListener("keydown", (e) => {
    //   if (e.code == "KeyP") {
    //     this.playVideo();
    //   }
    //   if (e.code == "KeyO") {
    //     this.pauseVideo();
    //   }
    // });

    window.addEventListener("devicemotion", (event) => {
      if (!this.dt) return;
      const velocity =
        window.innerHeight > window.innerWidth
          ? event.rotationRate.beta
          : event.rotationRate.alpha;

      const target = velocity > 0.0 ? 1.0 : -1.0;
      this.deviceMotion +=
        (target - this.deviceMotion) *
        (Math.abs(velocity) / 180) *
        this.state.gyroVelocity *
        (this.dt / 16);
      // this.deviceMotion = Math.min(1.0, Math.max(-1.0, this.deviceMotion));
    });

    hfactor = window.innerWidth / window.innerHeight / 2.1;
    hfactor =
      1.0 -
      (hfactor > 1.0
        ? this.state.fovModifierWide * hfactor
        : hfactor < 0.5
        ? -this.state.fovModifierNarrow * hfactor
        : 0.0);

    window.addEventListener("resize", () => {
      this.holoCamera.aspect = window.innerWidth / window.innerHeight;
      this.holoCamera.updateProjectionMatrix();

      hfactor = window.innerWidth / window.innerHeight / 2.1;
      hfactor =
        1.0 -
        (hfactor > 1.0
          ? this.state.fovModifierWide * hfactor
          : hfactor < 0.5
          ? -this.state.fovModifierNarrow * hfactor
          : 0.0);

      this.renderer.setSize(window.innerWidth, window.innerHeight);
    });
  }

  setHfactor() {
    hfactor = window.innerWidth / window.innerHeight / 2.1;
    hfactor =
      1.0 -
      (hfactor > 1.0
        ? this.state.fovModifierWide * hfactor
        : hfactor < 0.5
        ? -this.state.fovModifierNarrow * hfactor
        : 0.0);
  }

  setPixelRatio() {
    const { state } = this;

    this.transitionRenderTarget1.setSize(
      state.pixelRatio * window.innerWidth,
      state.pixelRatio * window.innerHeight,
    );

    this.transitionRenderTarget2.setSize(
      state.pixelRatio * window.innerWidth,
      state.pixelRatio * window.innerHeight,
    );

    if (this.renderTarget)
      this.renderTarget.setSize(
        state.pixelRatio * window.innerWidth,
        state.pixelRatio * window.innerHeight,
      );

    if (!this.composed) this.renderer.setPixelRatio(state.pixelRatio);
  }

  setMouseOffset(mX, mY) {
    this.mouseX = tmouseX + (this.state.reverseX ? -mX : mX);
    this.mouseY = tmouseY + (this.state.reverseY ? -mY : mY);
  }

  getTimeDelta() {
    return Math.abs(
      this.endTargetTransitionStructure.time - this.endTransitionStructure.time,
    );
  }

  injectState(inState) {
    this.state = inState;
  }

  readState(inState) {
    const badNames = [];
    const recursiveStateUpdate = (inState, toState) => {
      if (Array.isArray(toState)) {
        for (let i = 0; i < toState.length; i++) {
          if (typeof toState[i] === "object")
            recursiveStateUpdate(inState[i], toState[i]);
          else toState[i] = inState[i];
        }
      } else {
        Object.keys(toState).forEach((key) => {
          if (badNames.indexOf(key) !== -1) return;
          if (
            typeof inState[key] !== "undefined" &&
            typeof inState[key] !== "object"
          ) {
            toState[key] = inState[key];
          } else if (inState[key] && typeof inState[key] === "object") {
            recursiveStateUpdate(inState[key], toState[key]);
          }
        });
      }
    };

    recursiveStateUpdate(inState, this.state);
    this.setParallax();
    this.setPlaneScale();
    this.setBonusMode();
    this.setNumLayers();
    this.setGrain();
  }

  //Listeners
  onDocumentWheel(instance) {
    if (!instance.state.fovKeyFramed) {
      instance.holoCamera.fov += -window.event.wheelDelta * 0.002;
      holoCamera.fov *= hfactor;
      instance.holoCamera.updateProjectionMatrix();
    }
  }

  onDocumentMouseMove(event) {
    tmouseX = event.clientX - window.innerWidth / 2;
    tmouseY = event.clientY - window.innerHeight / 2;

    pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
  }

  onDocumentTouchMove(event) {
    event.preventDefault();

    let touchEvent = event.touches[0];

    tmouseX = touchEvent.clientX - window.innerWidth / 2;
    tmouseY = touchEvent.clientY - window.innerHeight / 2;

    pointer.x = (touchEvent.clientX / window.innerWidth) * 2 - 1;
    pointer.y = -(touchEvent.clientY / window.innerHeight) * 2 + 1;
  }

  //Keyframes
  getKeyFramedParametersCarValue(time, index) {
    const keyframes = this.state.scene.cars[index].keyframes;

    const value = {
      position: { x: 0, y: 0, z: 0 },
      rotation: { x: 0, y: 0, z: 0 },
      scale: { x: 1, y: 1, z: 1 },
    };

    for (let i = 0; i < keyframes.length - 1; i++) {
      if (time >= keyframes[i].t && time <= keyframes[i + 1].t) {
        const coef =
          (time - keyframes[i].t) / (keyframes[i + 1].t - keyframes[i].t);

        value["position"].x =
          (1 - coef) * keyframes[i]["position"].x +
          coef * keyframes[i + 1]["position"].x;

        value["position"].y =
          (1 - coef) * keyframes[i]["position"].y +
          coef * keyframes[i + 1]["position"].y;

        value["position"].z =
          (1 - coef) * keyframes[i]["position"].z +
          coef * keyframes[i + 1]["position"].z;

        value["rotation"].x =
          (1 - coef) * keyframes[i]["rotation"].x +
          coef * keyframes[i + 1]["rotation"].x;

        value["rotation"].y =
          (1 - coef) * keyframes[i]["rotation"].y +
          coef * keyframes[i + 1]["rotation"].y;

        value["rotation"].z =
          (1 - coef) * keyframes[i]["rotation"].z +
          coef * keyframes[i + 1]["rotation"].z;

        value["scale"].x =
          (1 - coef) * keyframes[i]["scale"].x +
          coef * keyframes[i + 1]["scale"].x;

        value["scale"].y =
          (1 - coef) * keyframes[i]["scale"].y +
          coef * keyframes[i + 1]["scale"].y;

        value["scale"].z =
          (1 - coef) * keyframes[i]["scale"].z +
          coef * keyframes[i + 1]["scale"].z;
      }
    }

    return value;
  }

  getKeyFramedParametersValue(time) {
    const keyframes = this.state.keyframes;

    const value = {
      fov: 0,
      cameraPosition: { x: 0, y: 0, z: 0 },
      texturePosition: { x: 0, y: 0, z: 0 },
      textureScale: { x: 0, y: 1 },
      stretchFactor: 1.0,
    };

    for (let i = 0; i < keyframes.length - 1; i++) {
      if (time >= keyframes[i].t && time <= keyframes[i + 1].t) {
        const coef =
          (time - keyframes[i].t) / (keyframes[i + 1].t - keyframes[i].t);

        value["stretchFactor"] =
          (1 - coef) * keyframes[i]["stretchFactor"] +
          coef * keyframes[i + 1]["stretchFactor"];

        value["fov"] =
          (1 - coef) * keyframes[i]["fov"] + coef * keyframes[i + 1]["fov"];

        value["cameraPosition"].x =
          (1 - coef) * keyframes[i]["cameraPosition"].x +
          coef * keyframes[i + 1]["cameraPosition"].x;

        value["cameraPosition"].y =
          (1 - coef) * keyframes[i]["cameraPosition"].y +
          coef * keyframes[i + 1]["cameraPosition"].y;

        value["cameraPosition"].z =
          (1 - coef) * keyframes[i]["cameraPosition"].z +
          coef * keyframes[i + 1]["cameraPosition"].z;

        value["texturePosition"].x =
          (1 - coef) * keyframes[i]["texturePosition"].x +
          coef * keyframes[i + 1]["texturePosition"].x;

        value["texturePosition"].y =
          (1 - coef) * keyframes[i]["texturePosition"].y +
          coef * keyframes[i + 1]["texturePosition"].y;

        value["texturePosition"].z =
          (1 - coef) * keyframes[i]["texturePosition"].z +
          coef * keyframes[i + 1]["texturePosition"].z;

        value["textureScale"].x =
          (1 - coef) * keyframes[i]["textureScale"].x +
          coef * keyframes[i + 1]["textureScale"].x;

        value["textureScale"].y =
          (1 - coef) * keyframes[i]["textureScale"].y +
          coef * keyframes[i + 1]["textureScale"].y;
      }
    }

    return value;
  }

  //Video methods
  playVideo(forcePlay = false) {
    // this.dvideo.play();
    this.toPlay = true;

    if (forcePlay) {
      const currentDvideo = this.dvideos[this.to];
      currentDvideo.play();
    }
  }

  pauseVideo(forcePause = false) {
    // this.dvideo.pause();
    this.toPlay = false;

    if (forcePause) {
      const currentDvideo = this.dvideos[this.to];
      currentDvideo.pause();
    }
  }

  setVideoTime(time) {
    const { dvideos } = this;
    const dvideo = dvideos[this.to];
    dvideo.currentTime = time * dvideo.duration;
  }

  //NAME should be passed
  setUpAndLoadVideo(name) {
    const { dvideo, dvideo2 } = this;
    dvideo.muted = true;
    dvideo.src = "videos/" + name + "/video.mp4";
    dvideo.setAttribute("type", "video/mp4");
    dvideo.playsInline = true;
    dvideo.loop = false;
    dvideo.load();

    this.vdtex = new THREE.VideoTexture(dvideo);

    dvideo2.muted = true;
    dvideo2.src = "videos/" + name + "/video.mp4";
    dvideo2.setAttribute("type", "video/mp4");
    dvideo2.playsInline = true;
    dvideo2.loop = false;
    dvideo2.load();

    this.vdtex2 = new THREE.VideoTexture(dvideo2);

    this.vdtexs = [this.vdtex, this.vdtex2];
  }

  // Plane updates
  setParallax() {
    this.plane.material.defines.PARALLAX_TYPE =
      parallaxDict[this.state.parallaxType];
    this.plane.material.needsUpdate = true;
  }

  setPlaneScale() {
    const { state, plane } = this;
    plane.scale.set(state.scale, state.scale, state.scale);
  }

  setBonusMode() {
    const { state, plane } = this;
    plane.material.defines["BONUS_MODE"] = state.bonusMode;
    plane.material.needsUpdate = true;
  }
  setNumLayers() {
    const { state, plane } = this;
    plane.material.defines["NUM_LAYERS"] = state.numLayers;
    plane.material.needsUpdate = true;
  }

  setGrain() {
    const { state, plane } = this;
    plane.material.defines["GRAIN"] = state.grain;
    plane.material.needsUpdate = true;
  }

  //Scene settings and updates
  setBackground() {
    const { state, plane } = this;
    scene.background = new THREE.Color(state.background);
  }

  updateSceneFromState() {
    const { holoPlanes, holoBoxes, state } = this;

    state.scene.planes.forEach((planed, i) => {
      holoPlanes[i].position.set(
        planed.position.x,
        planed.position.y,
        planed.position.z,
      );
      holoPlanes[i].scale.set(planed.scale.x, planed.scale.y, 1.0);
      holoPlanes[i].rotation.set(
        planed.rotation.x,
        planed.rotation.y,
        planed.rotation.z,
      );
      holoPlanes[i].visible = planed.visible;
    });

    state.scene.boxes.forEach((boxd, i) => {
      holoBoxes[i].position.set(
        boxd.position.x,
        boxd.position.y,
        boxd.position.z,
      );
      holoBoxes[i].scale.set(boxd.scale.x, boxd.scale.y, boxd.scale.z);
      holoBoxes[i].rotation.z = boxd.rotation;
      holoBoxes[i].visible = boxd.visible;
    });
  }

  initializeScene() {
    this.scene = new THREE.Scene();

    this.animationStructures = {};
    this.holoMeshes = [];
    this.intersectionObjects = [];
    this.holoPlanes = [];
    this.holoCars = [];
    this.holoBoxes = [];

    const hHeight = 0.5;
    const hWidth = (window.innerWidth * hHeight) / window.innerHeight;

    this.camera = new THREE.OrthographicCamera(
      -hWidth,
      hWidth,
      hHeight,
      -hHeight,
      -0.1,
      100,
    );

    const scale = 1.2;
    this.plane = new THREE.Mesh(
      new THREE.PlaneGeometry(
        (scale * 2 * 16 * hHeight) / 9,
        scale * 2 * hHeight,
      ),
      texMaterial(),
    );
    this.plane.position.z = -0.8;
    this.plane.material.uniforms.cropy.value = 0.1;
    this.plane.frustumCulled = false;

    this.setParallax();
    this.setNumLayers();
    this.setGrain();
    this.scene.add(this.plane);

    this.chosenCarName = null;
    this.chosenCarTimeSelected = null;

    this.chosenBoxName = null;
    this.chosenBoxTimeSelected = null;

    this.holoScene = new THREE.Scene();
    this.holoCamera = new THREE.PerspectiveCamera(
      this.state.scene.camera.fov,
      window.innerWidth / window.innerHeight,
      0.001,
      8.0,
    );
    this.holoCamera.fov *= hfactor;

    const {
      holoMeshes,
      holoBoxes,
      holoPlanes,
      holoCars,
      holoScene,
      intersectionObjects,
      animationStructures,
      scene,
      state,
      plane,
      renderer,
    } = this;

    state.scene.planes.forEach((planed, i) => {
      const planeObject = new THREE.Object3D();
      const plane = new THREE.Mesh(
        new THREE.PlaneGeometry(
          (scale * 2 * 16 * hHeight) / 9,
          scale * 2 * hHeight,
          1,
          1,
        ),
        wireframeMaterial(),
      );
      plane.material.wireframe = false;
      plane.material.depthTest = false;

      const lineLeft = new THREE.Mesh(
        new THREE.CylinderGeometry(1, 1, 1, 12, 64),
        roadlineMaterial(),
      );

      const lineRight = new THREE.Mesh(
        new THREE.CylinderGeometry(1, 1, 1, 12, 64),
        roadlineMaterial(),
      );
      lineRight.material.defines.LEFT = false;

      planeObject.add(plane);
      planeObject.add(lineLeft);
      planeObject.add(lineRight);

      holoScene.add(planeObject);
      holoMeshes.push(plane);
      holoMeshes.push(lineLeft);
      holoMeshes.push(lineRight);

      holoPlanes.push(planeObject);
      intersectionObjects.push(plane);
      plane.name = "Plane" + i;

      animationStructures[plane.name] = {
        s: 0,
        targetS: 0,
        velocity: 0.005,
        meshData: [
          {
            mesh: plane,
            f: (t) => Math.min(4 * t, 1.0),
          },
          {
            mesh: lineLeft,
            f: (t) => t,
          },
          {
            mesh: lineRight,
            f: (t) => t,
          },
        ],
      };
    });

    state.scene.boxes.forEach((boxd, i) => {
      const partitions = 2;
      const box = new THREE.Mesh(
        new THREE.BoxGeometry(1, 1, 1, partitions, partitions, partitions),
        wireframeMaterial(),
      );
      box.material.wireframe = false;

      const lineUp = new THREE.Mesh(
        new THREE.CylinderGeometry(1, 1, 1, 12, 64),
        buildingborderMaterial(),
      );

      const lineDown = new THREE.Mesh(
        new THREE.CylinderGeometry(1, 1, 1, 12, 64),
        buildingborderMaterial(),
      );
      lineDown.material.defines.UP = false;

      const p = new THREE.Mesh(
        new THREE.PlaneGeometry(1, 1),
        buildPlaneMaterial(),
      );
      p.material.wireframe = false;
      p.material.defines.UPSIDEDOWN = i == 0;
      p.rotation.x = Math.PI / 2;
      p.position.y = i == 0 ? -0.5 : 0.5;
      p.material.depthTest = false;
      p.material.side = THREE.DoubleSide;

      const tr = new TextRenderer("Building " + i, renderer, 1.2, 0.8, () => {
        tr.render();
        p.material.uniforms.tex.value = tr.get();
      });
      // setTimeout(() => {
      //   tr.render();
      //   p.material.uniforms.tex.value = tr.get();
      // }, 500);

      p.add(lineUp);
      p.add(lineDown);

      const bObject = new THREE.Object3D();
      bObject.add(box);
      bObject.add(p);

      holoBoxes.push(bObject);
      holoScene.add(bObject);

      holoMeshes.push(box);
      holoMeshes.push(p);
      holoMeshes.push(lineUp);
      holoMeshes.push(lineDown);
      // box.visible = false;

      intersectionObjects.push(box);
      box.name = "Box" + i;

      animationStructures[box.name] = {
        s: 0,
        targetS: 0,
        velocity: 0.02,
        meshData: [
          {
            mesh: box,
            f: (t) => t,
            side: () => {
              if (this.chosenBoxName) {
                animationStructures[this.chosenBoxName].meshData.forEach(
                  (md) => {
                    md.mesh.frustumCulled = true;
                  },
                );
              }

              this.chosenBoxName = box.name;
              animationStructures[this.chosenBoxName].meshData.forEach((md) => {
                md.mesh.frustumCulled = false;
              });

              this.chosenBoxTimeSelected = Date.now();
              // if (state.useHoverPause) {
              //   this.intersectionPaused = true;
              // }
            },
          },
          {
            mesh: p,
            f: (t) => t,
          },
          {
            mesh: lineDown,
            f: (t) => t,
          },
          {
            mesh: lineUp,
            f: (t) => t,
          },
        ],
      };
    });

    state.scene.cars.forEach((card, i) => {
      const partitions = 1;
      const car = new THREE.Mesh(
        new THREE.BoxGeometry(1, 1, 1, partitions, partitions, partitions),
        wireframeMaterial(),
      );
      car.geometry.boundingBox = new THREE.Box3(
        new THREE.Vector3(-8, -8, -8),
        new THREE.Vector3(8, 8, 8),
      );
      car.geometry.boundingSphere = new THREE.Sphere(
        new THREE.Vector3(0, 0, 0),
        8.5,
      );
      car.material.defines.CAR = true;

      const cardInstance = new Card(state, renderer);

      const line = new THREE.Mesh(
        new THREE.CylinderGeometry(1, 1, 1, 12, 64),
        cardlineMaterial(),
      );
      // line.scale.set(0.3, 0.3, 0.3);

      const carObject = new THREE.Object3D();
      const carNoRotationObject = new THREE.Object3D();

      carObject.add(car);
      carObject.add(line);
      carObject.add(cardInstance.lineUp);
      carObject.add(cardInstance.lineDown);
      carNoRotationObject.add(cardInstance.background);

      car.material.depthTest = false;
      holoScene.add(carObject);
      holoScene.add(carNoRotationObject);
      holoCars.push({
        carObject,
        carNoRotationObject,
      });

      holoMeshes.push(car);
      holoMeshes.push(line);
      holoMeshes.push(cardInstance.lineUp);
      holoMeshes.push(cardInstance.lineDown);
      holoMeshes.push(cardInstance.background);

      const tr = new TextRenderer("Car " + i, renderer, 2.0, 1.2, () => {
        setTimeout(() => {
          tr.render();
          cardInstance.background.material.uniforms.tex.value = tr.get();
        }, 500);
      });

      // setTimeout(() => {
      //   tr.render();
      //   cardinstance.background.material.uniforms.tex.value = tr.get();
      // }, 500);

      intersectionObjects.push(car);
      car.name = "Car" + i;

      animationStructures[car.name] = {
        s: 0,
        targetS: 0,
        velocity: 0.02,
        meshData: [
          {
            mesh: car,
            f: (t) => t,
            side: () => {
              if (this.chosenCarName) {
                animationStructures[this.chosenCarName].meshData.forEach(
                  (md) => {
                    md.mesh.frustumCulled = true;
                  },
                );
              }

              this.chosenCarName = car.name;
              animationStructures[this.chosenCarName].meshData.forEach((md) => {
                md.mesh.frustumCulled = false;
              });

              this.chosenCarTimeSelected = Date.now();
              if (state.useHoverPause) {
                this.intersectionPaused = true;
              }
            },
          },
          {
            mesh: line,
            f: (t) => (t < 0.5 ? t / 0.5 : 1),
          },
          {
            mesh: cardInstance.lineUp,
            f: (t) => {
              if (t < 0.5) return 0;
              else if (t >= 0.5 && t < 0.8) return (t - 0.5) / 0.3;
              else return 1.0;
            },
          },
          {
            mesh: cardInstance.lineDown,
            f: (t) => {
              if (t < 0.5) return 0;
              else if (t >= 0.5 && t < 0.8) return (t - 0.5) / 0.3;
              else return 1.0;
            },
          },
          {
            mesh: cardInstance.background,
            f: (t) => {
              if (t < 0.8) return 0;
              else return (t - 0.8) / 0.2;
            },
          },
        ],
      };
    });

    this.updateSceneFromState(state);

    this.taa = new TaaModule(this.vdtex);
    this.taa2 = new TaaModule(this.vdtex2);
    this.taas = [this.taa, this.taa2];

    this.transitionScene = new THREE.Scene();
    this.transitionPlane = new THREE.Mesh(
      new THREE.PlaneGeometry(1, 1),
      transitionMaterial(),
    );
    this.transitionPlane.frustumCulled = false;
    this.transitionScene.add(this.transitionPlane);
    this.transitionCamera = new THREE.PerspectiveCamera();
  }

  renderTransition(fromtexture, totexture, renderTarget) {
    const { state, plane, renderer, transitionScene, transitionCamera } = this;

    this.transitionPlane.material.uniforms.tex1.value = fromtexture;
    this.transitionPlane.material.uniforms.tex2.value = totexture;
    this.transitionPlane.material.uniforms.reverse.value = 1;

    this.transitionPlane.material.uniforms.transition.value =
      this.endTransitionStructure.transition;
    this.transitionPlane.material.uniforms.stretch.value =
      this.endTransitionStructure.stretch;
    this.transitionPlane.material.uniforms.time.value = this.time;
    this.transitionPlane.material.uniforms.innerTimeMultiplier.value =
      state.innerTimeMultiplier;
    this.transitionPlane.material.uniforms.stretchMultiplier.value.set(
      state.stretchMultiplier.x,
      state.stretchMultiplier.y,
    );

    renderer.setRenderTarget(renderTarget);

    renderer.clear();
    renderer.clearDepth();
    renderer.render(transitionScene, transitionCamera);
  }

  renderVideo(time, taa, vdtex, renderTarget) {
    const {
      state,
      plane,
      renderer,
      scene,
      holoScene,
      holoCamera,
      holoCars,
      holoMeshes,
    } = this;

    let mouseX = this.state.reverseX ? -this.mouseX : this.mouseX;
    const mouseY = this.state.reverseY ? -this.mouseY : this.mouseY;

    if (state.useGyro) {
      mouseX -= 3 * window.innerWidth * this.deviceMotion; // + window.innerWidth / 2;
    }

    // Keyframes Handling
    const startCameraPosition = new THREE.Vector3(0, 0, 0);
    let stretchFactor = 1;
    let keyFramedFov = 90;
    if (!state.useKeyFrames) {
      plane.position.x = state.scene.texture.position.x;
      plane.position.y = state.scene.texture.position.y;
      plane.position.z = state.scene.texture.position.z;

      plane.scale.x = state.scene.texture.scale.x;
      plane.scale.y = state.scene.texture.scale.y;
      // plane.scale.z = state.scene.texture.scale.z;

      plane.rotation.y = state.scene.texture.rotation;

      startCameraPosition.set(
        state.scene.camera.position.x,
        state.scene.camera.position.y,
        state.scene.camera.position.z,
      );
    } else {
      const value = this.getKeyFramedParametersValue(time);
      plane.position.x = value.texturePosition.x;
      plane.position.y = value.texturePosition.y;
      plane.position.z = value.texturePosition.z;

      plane.scale.x = value.textureScale.x;
      plane.scale.y = value.textureScale.y;
      // plane.scale.z = value.textureScale.z;

      plane.rotation.y = state.scene.texture.rotation;
      stretchFactor = value.stretchFactor;
      keyFramedFov = value.fov;

      startCameraPosition.set(
        value.cameraPosition.x,
        value.cameraPosition.y,
        value.cameraPosition.z,
      );
    }
    plane.material.uniforms.rotation.value = this.rotation;
    plane.material.uniforms.scaleRotation.value =
      this.state.backgroundRotationScale;

    holoCars.forEach((carTuple, index) => {
      const value = this.getKeyFramedParametersCarValue(time, index);

      const car = carTuple.carObject;
      const carNoRotation = carTuple.carNoRotationObject;

      car.position.set(value.position.x, value.position.y, value.position.z);
      car.rotation.set(value.rotation.x, value.rotation.y, value.rotation.z);
      car.scale.set(value.scale.x, value.scale.y, value.scale.z);

      carNoRotation.position.set(
        value.position.x,
        value.position.y,
        value.position.z,
      );
      carNoRotation.scale.set(value.scale.x, value.scale.y, value.scale.z);
    });

    //Holocamera
    const s = state.scene.camera.s;
    const v = new THREE.Vector3(
      (state.velx * mouseX) / (window.innerWidth / 2),
      (state.vely * mouseY) / (window.innerHeight / 2),
      0,
    );

    if (state.fovKeyFramed) {
      holoCamera.fov = keyFramedFov; //state.scene.camera.fov;
      holoCamera.fov *= hfactor;

      holoCamera.updateProjectionMatrix();
    }

    holoCamera.position.x = startCameraPosition.x + s * v.x;
    holoCamera.position.y =
      startCameraPosition.y +
      (s * v.y * window.innerHeight) / window.innerWidth;

    holoCamera.position.z = startCameraPosition.z;
    if (state.fovKeyFramed)
      holoCamera.lookAt(
        new THREE.Vector3(
          state.scene.texture.position.x,
          state.scene.texture.position.y,
          state.scene.texture.position.z,
        ),
      );
    else
      holoCamera.lookAt(
        new THREE.Vector3(0, 0, state.scene.texture.position.z),
      );

    //Meshes mouse/camera update
    holoMeshes.forEach((hm) => {
      const refAspect = 1490 / 926;
      const aspect = window.innerWidth / window.innerHeight;

      hm.material.uniforms.rotation.value = this.rotation;
      hm.material.uniforms.scaleRotation.value =
        this.state.backgroundRotationScale;
      hm.material.uniforms.mouse.value.set(
        -(
          (stretchFactor * 0.06 * mouseX * (s / 0.007)) /
          (window.innerWidth / 2)
        ) * 1.0,
        (0.5 *
          (refAspect / aspect) *
          (stretchFactor * 0.06 * mouseY * (s / 0.007))) /
          (window.innerHeight / 2),
      );
    });

    // taa.render(dt, state, renderer);
    plane.material.uniforms.dtex.value = state.usetaa
      ? taa.getTexture()
      : vdtex;

    renderer.setRenderTarget(renderTarget);
    renderer.autoClear = false;
    // renderer.setClearColor("#50727B");
    renderer.clear();
    renderer.clearDepth();
    renderer.render(scene, holoCamera);
    renderer.render(holoScene, holoCamera);
  }

  swap() {
    this.from++;
    this.to++;

    this.from = this.from % 2;
    this.to = this.to % 2;
  }

  startTransition() {
    this.endTransitionStructure.time = 0;
    this.transitionRunning = true;
    this.swap();

    const dvideo = this.dvideos[this.to];
    dvideo.currentTime = 0;
    // dvideo.play();
  }

  animate(DT, useRenderTarget = false) {
    if (!this.composed)
      requestAnimationFrame(() => {
        this.animate();
      });

    //Time update
    const dt = DT ? DT : Math.min(Date.now() - prev, 60);
    this.dt = dt;
    this.rotation +=
      (dt / 8) * 0.0012 * this.state.backgroundRotationVelocity * Math.PI;
    this.rotation = this.rotation % (2 * Math.PI);

    this.mouseX += 0.015 * (dt / 8) * (tmouseX - this.mouseX);
    this.mouseY += 0.015 * (dt / 8) * (tmouseY - this.mouseY);

    //Transition for the end of the video
    this.time += (30.0 * dt) / 8;
    this.endTransitionStructure.time +=
      this.state.transitionVelocity *
      (dt / 8) *
      (this.endTargetTransitionStructure.time -
        this.endTransitionStructure.time);

    const finalTime = this.endTransitionStructure.time;

    this.endTransitionStructure.transition = getTransition(
      finalTime,
      this.state,
    );
    this.endTransitionStructure.stretch = getStretch(
      this.endTransitionStructure.transition,
      this.state,
    );

    const {
      holoCamera,
      intersectionObjects,
      animationStructures,
      state,
      plane,
      taa,
      renderer,
    } = this;

    const currentDvideo = this.dvideos[this.to];
    state.runningTime = currentDvideo.currentTime / currentDvideo.duration;
    this.intersectionPaused = false;

    //Stats
    if (!this.composed) this.stats.update();

    //Texture uniforms/parameters settings
    plane.material.uniforms.time.value = performance.now();
    plane.material.uniforms.dtex.value = state.usetaa
      ? taa.getTexture()
      : this.vdtex;
    plane.material.uniforms.cropy.value = state.cropy;
    plane.material.uniforms.depthScale.value = state.depthScale;
    plane.material.uniforms.depthThreshold.value = state.depthThreshold;

    plane.material.uniforms.nearestDepth.value = state.scene.texture.position.z;
    plane.material.uniforms.velx.value = state.velx;
    plane.material.uniforms.vely.value = state.vely;

    //Update Chosen car

    if (this.chosenCarName && state.useHoverTimeout) {
      if (Date.now() - this.chosenCarTimeSelected > state.hoverTimeout) {
        const currentChosenName = this.chosenCarName;
        setTimeout(() => {
          if (currentChosenName != this.chosenCarName)
            animationStructures[currentChosenName].meshData.forEach((md) => {
              md.mesh.frustumCulled = true;
            });
        }, 2000);
        this.chosenCarName = null;
      }
    }

    if (this.chosenBoxName && state.useHoverTimeout) {
      if (Date.now() - this.chosenBoxTimeSelected > state.hoverTimeout) {
        const currentChosenName = this.chosenBoxName;
        setTimeout(() => {
          if (currentChosenName != this.chosenBoxName)
            animationStructures[currentChosenName].meshData.forEach((md) => {
              md.mesh.frustumCulled = true;
            });
        }, 2000);
        this.chosenBoxName = null;
      }
    }

    //Animation structure default target settings
    Object.keys(animationStructures).forEach((key) => {
      if (key != this.chosenCarName && key != this.chosenBoxName) {
        const as = animationStructures[key];
        as.targetS = 0.0;
      }
    });

    //Intersection handling
    raycaster.setFromCamera(pointer, holoCamera);
    const intersects = raycaster.intersectObjects(intersectionObjects);
    for (let i = 0; i < intersects.length; i++) {
      // console.log(intersects[0].point);
      const name = intersects[i].object.name;
      const as = animationStructures[name];
      as.targetS = 1.0;

      //Side effects for intersection. Slow down for hover. Need more fps?
      as.meshData.forEach((md) => {
        const mesh = md.mesh;
        if (md.side) md.side();
      });
    }

    //Update animation structures
    Object.keys(animationStructures).forEach((key) => {
      const as = animationStructures[key];
      as.s += (dt / 8) * as.velocity * (as.targetS - as.s);

      as.meshData.forEach((md) => {
        const mesh = md.mesh;
        mesh.material.uniforms.s.value = md.f(as.s);
      });
    });

    //Pause or play
    if (
      this.toPlay &&
      !this.intersectionPaused &&
      (currentDvideo.paused || currentDvideo.currentTime == 0)
    ) {
      currentDvideo.play();
    }
    if (!this.toPlay || (this.intersectionPaused && !currentDvideo.paused)) {
      currentDvideo.pause();
    }

    //Update scene from state
    this.updateSceneFromState(state);

    const transitionRunning = this.getTimeDelta() > 0.15;

    if (state.usetaa) this.taas[this.to].render(dt, state, renderer);

    // console.log(this.getTimeDelta());

    if (transitionRunning) {
      if (state.usetaa) this.taas[this.from].render(dt, state, renderer);

      const time1 = this.dvideo.currentTime / this.dvideo.duration;
      const time2 = this.dvideo2.currentTime / this.dvideo.duration;

      this.renderVideo(
        time1,
        this.taa,
        this.vdtex,
        this.transitionRenderTarget1,
      );
      this.renderVideo(
        time2,
        this.taa2,
        this.vdtex2,
        this.transitionRenderTarget2,
      );

      //Render Transition pass
      if (this.composed && useRenderTarget)
        this.renderTransition(
          this.transitionRenderTargets[this.from].texture,
          this.transitionRenderTargets[this.to].texture,
          this.renderTarget,
        );
      else
        this.renderTransition(
          this.transitionRenderTargets[this.from].texture,
          this.transitionRenderTargets[this.to].texture,
          null,
        );
    } else {
      this.transitionRunning = false;
      const time =
        this.dvideos[this.to].currentTime / this.dvideos[this.to].duration;
      if (this.composed && useRenderTarget)
        this.renderVideo(
          time,
          this.taas[this.to],
          this.vdtexs[this.to],
          this.renderTarget,
        );
      else
        this.renderVideo(time, this.taas[this.to], this.vdtexs[this.to], null);
    }

    if (state.runningTime > state.finishTime && !this.transitionRunning) {
      this.startTransition();
    }

    prev = Date.now();
  }
}

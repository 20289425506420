import * as THREE from "three";
import vertex from "./tex2.glslv";
import fragment from "./tex2.glslf";

const tex2Material = () => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    // transparent: true,
    blending: THREE.NormalBlending,
    // blending: THREE.AdditiveBlending,
    uniforms: {
      ctex: { value: null },
      dtex: { value: null },
      cropy: { value: 0.0 },
      depthScale: { value: 0.0 },
      mouse: { value: new THREE.Vector2() },
      time: { value: 0 },
      velx: { value: 0 },
      vely: { value: 0 },
      nearestDepth: { value: 0 },
      depthThreshold: { value: 1 },
      rotation: { value: 0 },
      scaleRotation: { value: 1 },
    },
    defines: {
      PARALLAX_TYPE: 2,
      SEPARATE: false,
      NUM_LAYERS: 32,
      GRAIN: false,
      BONUS_MODE: false,
    },
  });
};

export default tex2Material;

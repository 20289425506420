import * as THREE from "three";
import cardborderMaterial from "../../materials/lines/cardborder";
import cardBackgroundMaterial from "../../materials/cardBackground";

export class Card {
  constructor(state, renderer) {
    this.state = state;
    this.renderer = renderer;

    this.s = 0.0;
    this.object = new THREE.Object3D();
    this.lineUp = new THREE.Mesh(
      new THREE.CylinderGeometry(1, 1, 1, 12, 64),
      cardborderMaterial(),
    );
    this.lineDown = new THREE.Mesh(
      new THREE.CylinderGeometry(1, 1, 1, 12, 64),
      cardborderMaterial(),
    );
    this.lineDown.material.defines.UP = false;
    this.background = new THREE.Mesh(
      new THREE.PlaneGeometry(1, 1),
      cardBackgroundMaterial(),
    );
    // this.background.rotation.x = Math.PI / 2;
    this.background.scale.set(5.3, 0.9, 1.0);
    this.background.material.side = THREE.DoubleSide;

    // this.picture = new THREE.Mesh();
    // this.textRender = new THREE.Mesh();
    // this.textScene = new THREE.Scene();
    // this.textCamera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, -2, 2);
    this.lineUp.material.uniforms.s.value = 1.0;
    this.lineDown.material.uniforms.s.value = 1.0;
  }

  update(s) {
    this.lineUp.material.uniforms.s.value = 1.0;
    this.lineDown.material.uniforms.s.value = 1.0;
  }
}
